<template>
  <div>
    <b-modal
      no-close-on-backdrop
      scrollable
      v-model="show"
      id="modal-scrollable"
      hide-footer
      hide-header
      centered
    >
      <b-card-text>
        <div class="header text-right">
          <b-button @click="closePopUp" variant="flat-primary"
            ><feather-icon icon="XIcon"></feather-icon
          ></b-button>
        </div>
        <div class="pl-2 pr-2 pb-2 pt-0 edit-form">
          <h3 class="app-title md">Share "{{ client.name }}" with others</h3>
          <b-form-group
            label="Invite people"
            label-for="invite-people"
            class="mt-2"
          >
            <div class="project-form-container">
              <v-select
                multiple
                placeholder="Add Email ID"
                @open="fetchUsers"
                v-model="newAccess"
                label="email"
                :options="users"
                :loading="fetchingUsers"
                @search="checkEmail"
                class="full-width"
                no-data-text=""
                @input="addNewAccessField"
              />
            </div>
          </b-form-group>
          <div
            v-if="newAccess.length > 0"
            style="background-color: #f0f0f0; padding: 8px; border-radius: 5px; font-size: 11px;"
          >
            <span><strong>Project configurations</strong></span>
            <div v-for="(i, index) in newAccess">
              <div
                style="display: flex; justify-content: space-between; padding: 0 7px;"
              >
                <span>
                  <strong>{{ i.email }}</strong>
                </span>
                <span>
                  <feather-icon
                    icon="EditIcon"
                    color="#200E6B"
                    @click="selectProjectConfig(i, index)"
                    style="cursor: pointer;"
                  />
                </span>
              </div>
              <span>
                <div
                  v-for="(key, value) in i.accessConfiguration.projects.items"
                  style="padding: 0 10px;"
                >
                  <div>
                    <strong>{{ value }}:</strong>
                    {{ key ? "allowed" : "not allowed" }}
                  </div>
                  <div></div>
                </div>
              </span>
            </div>
          </div>

          <div class="d-flex justify-content-between mt-2">
            <div class="text-left">
              <b-button size="sm" variant="flat-primary"
                ><feather-icon size="20" icon="LinkIcon"></feather-icon
              ></b-button>
            </div>
            <div class="text-right">
              <b-button variant="light" @click="closePopUp" class="mr-1"
                >Cancel</b-button
              >
              <b-button
                :disabled="!newAccess.length || isProcessingAccess"
                @click="sendInvite"
                variant="primary"
              >
                <b-spinner v-if="isProcessingAccess" small class="mr-1" />
                Share</b-button
              >
            </div>
          </div>

          <hr class="mt-2 mb-2" />
          <h3 class="app-title sm">People with Access</h3>
          <div class="access-list mt-1">
            <div
              class="access my-1"
              v-for="access in client.accessList"
              :key="access._id"
            >
              <div
                class="access-details d-flex justify-content-between"
                v-if="access.adminID.lastname !== 'undefined'"
              >
                <div class="avatars d-flex">
                  <b-avatar
                    variant="light-success"
                    class="mr-1"
                    size="40"
                    text="ASM"
                  />
                  <div class="details">
                    <span class="d-block name">{{
                      `${access.adminID && access.adminID.firstname  ? access.adminID.firstname : '' } ${access.adminID && access.adminID.lastname ? access.adminID.lastname : '' }`
                    }}</span>
                    <span class="d-block email">{{
                      access.adminID.email
                    }}</span>
                  </div>
                </div>
                <div class="action-buttons">
                  <b-button
                    v-if="access.role != 'ADMIN'"
                    @click="updateConfiguration(access)"
                    class="btn-icon rounded-circle"
                    variant="flat-primary"
                    ><feather-icon icon="EditIcon"></feather-icon
                  ></b-button>
                  <b-button
                    v-if="!isProcessingRevokeAccess && access.role != 'ADMIN'"
                    @click="
                      removeAccessModal(
                        access.adminID._id,
                        `${access.adminID.firstname} ${access.adminID.lastname}`
                      )
                    "
                    class="btn-icon rounded-circle"
                    variant="flat-danger"
                    ><feather-icon icon="MinusCircleIcon"></feather-icon
                  ></b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card-text>
    </b-modal>
    <b-modal
      id="showScheduleNow"
      hide-footer
      hide-header
      v-model="showRemoveAccessModal"
    >
      <div class="d-block text-center">
        <h4>Are you sure want to revoke access of {{ adminName }}?</h4>
      </div>
      <div class="popup-modal">
        <b-button
          class="mt-2"
          @click="$bvModal.hide('showScheduleNow')"
          style="min-width: 150px"
          >Cancel</b-button
        >
        <b-button
          style="min-width: 150px"
          class="ml-1 mt-2"
          variant="primary"
          @click="removeAccess()"
        >
          Yes
        </b-button>
      </div>
    </b-modal>
    <b-modal
      no-close-on-backdrop
      v-model="showProjectConfigurationAccessModal"
      id="modal-scrollable"
      hide-footer
      hide-header
      centered
    >
      <b-card-text>
        <div class="header text-right">
          <b-button @click="closeSelectProjectConfig" variant="flat-primary"
            ><feather-icon icon="XIcon"></feather-icon
          ></b-button>
        </div>
        <div class="edit-form">
          <h3 class="app-title xs">
            Update Project Configurations for {{ this.clientDetail }}
          </h3>
        </div>
      </b-card-text>
      <!-- <b-form-group
        v-if="Object.keys(newAccess).length > 0"
        label="Enable Project Configuration"
        label-for="invite-people"
        class="mt-2"
      > -->
      <b-form-group
        label="Enable Project Configurations"
        label-for="invite-people"
        class="mt-2"
      >
        <div class="project-form-container">
          <template>
            <div class="projectConfigContainer">
              <div
                v-for="(value, key) in projectConfig"
                :key="key"
                class="option"
                v-if="key !== 'clients'"
              >
                <div class="card-config animate">
                  <h3
                    style="display: flex; justify-content: space-between; align-items: center; text-transform: capitalize; color: #200E6B; font-weight: bold; font-size: 14px"
                  >
                    {{ key }}
                    <input
                      v-if="!isObject(value)"
                      type="checkbox"
                      v-model="projectConfig[key]"
                      style="display: inline-block; vertical-align: middle;"
                    />
                  </h3>
                  <template v-if="isObject(value)">
                    <template v-for="(subValue, subKey) in value">
                      <div
                        :key="subKey"
                        v-if="isObject(subValue)"
                        class="nested"
                        style="margin-top: 5px;"
                      >
                        <span v-if="isCollapsed" style="cursor: pointer;">
                          <feather-icon
                            v-if="value.enabled == true"
                            @click="toggleCollapse"
                            icon="MinusCircleIcon"
                            color="blue"
                          /> </span
                        ><span
                          style="cursor: pointer;"
                          @click="toggleCollapse"
                          v-else
                        >
                          <feather-icon
                            v-if="value.enabled == true"
                            icon="PlusCircleIcon"
                            color="blue"
                            v-b-tooltip.hover.top="'View More'"
                          />
                        </span>
                        <ul v-if="isCollapsed" class="animate">
                          <li
                            v-for="(nestedValue, nestedKey) in subValue"
                            :key="nestedKey"
                            class="nested-item"
                          >
                            <span
                              style="display: inline-block; vertical-align: middle; text-transform: capitalize;"
                              >{{ nestedKey }}</span
                            >
                            <input
                              style="display: inline-block; vertical-align: middle; text-transform: capitalize;"
                              type="checkbox"
                              v-model="subValue[nestedKey]"
                            />
                          </li>
                        </ul>
                      </div>
                      <div
                        v-else
                        :key="subKey"
                        class="nested-item"
                        style="margin-top: -40px;"
                      >
                        <span></span>
                        <input
                          style="display: inline-block; vertical-align: middle; text-transform: capitalize;"
                          type="checkbox"
                          v-model="value[subKey]"
                        />
                      </div>
                    </template>
                  </template>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div style="float: right; margin-top: 20px;" v-if="isAddingProject">
          <b-button variant="primary" @click="updateLocalProjectConfiguration"
            >Update Configuration <b-spinner v-if="isUpdateSuccessFully" small
          /></b-button>
        </div>
        <div style="float: right; margin-top: 20px;" v-else>
          <b-button variant="primary" @click="updateProjectConfiguration"
            >Update <b-spinner v-if="isUpdateSuccessFully" small
          /></b-button>
        </div>
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal,
  BCardText,
  BButton,
  BFormInput,
  BInputGroup,
  BFormGroup,
  BSpinner,
  BAvatar
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";
import { getUserToken } from "@/auth/utils";
export default {
  props: {
    client: {
      type: Object,
      default: () => {
        return {
          clientID: "",
          name: "",
          sector: null,
          accessList: []
        };
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    isProcessingAccess: {
      type: Boolean,
      required: true,
      default: false
    },
    isProcessingRevokeAccess: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      clientDetail: "",
      projectIndex: 0,
      isAddingProject: false,
      adminId: "",
      updatedOneArray: {},
      isCollapsed: false,
      isUpdateSuccessFully: false,
      projectConfig: null,
      showProjectConfigurationAccessModal: false,
      newAccess: [],
      users: [],
      fetchingUsers: false,
      isValidEmail: false,
      showRemoveAccessModal: false,
      showAddButton: false,
      correctEmail: "",
      adminID: "",
      adminName: "",
      projectsData: {
        projects: {
          enabled: true,
          items: {
            create: false,
            delete: false,
            read: true
          }
        }
      }
    };
  },
  components: {
    BModal,
    BCardText,
    BButton,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BSpinner,
    BAvatar,
    ToastificationContent,
    vSelect
  },
  methods: {
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant
        }
      });
    },
    updateLocalProjectConfiguration() {
      let isEnabled = this.projectConfig.projects.enabled;
      let isReadEnabled = this.projectConfig.projects.items.read;
      let isDeleteEnabled = this.projectConfig.projects.items.delete;
      let isCreateEnabled = this.projectConfig.projects.items.create;
      if (this.projectIndex !== null && this.projectIndex !== undefined) {
        this.newAccess[this.projectIndex].accessConfiguration = {
          projects: {
            enabled: isEnabled,
            items: {
              create: isCreateEnabled,
              delete: isDeleteEnabled,
              read: isReadEnabled
            }
          }
        };
      }
      this.closeSelectProjectConfig();
      this.isAddingProject = false
    },
    addNewAccessField() {
      this.newAccess.forEach(item => {
        if (!item.accessConfiguration) {
          this.$set(item, "accessConfiguration",  {
        projects: {
          enabled: true,
          items: {
            create: false,
            delete: false,
            read: true
          }
        }
      });
        }
      });
    },
    updateProjectConfiguration() {
      this.isUpdateSuccessFully = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/edit-access`,
          {
            clientID: this.client.clientID,
            adminID: this.adminId,
            accessConfiguration: this.projectConfig
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.isCollapsed = false;
          this.closeSelectProjectConfig();
          this.showToast(
            "Success!",
            "Updated Project Configuration Successfully",
            "success"
          );
          this.isUpdateSuccessFully = false;
        })
        .catch(error => {
          this.isCollapsed = false;
          this.isUpdateSuccessFully = false;
          this.closeSelectProjectConfig();
          this.showToast("Error", "Unknown error occured!", "danger");
          console.log(error.response.data);
        });
    },
    updateConfiguration(access) {
      this.clientDetail = "";
      this.clientDetail =
        access.adminID.firstname + " " + access.adminID.lastname;
      this.adminId = "";
      this.projectConfig = null;
      this.adminId = access.adminID._id;
      this.projectConfig = access.accessConfiguration;
      this.showProjectConfigurationAccessModal = true;
    },
    convertedString(name) {
      if (!name) return;
      return name?.split("_").join(" ");
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    isObject(value) {
      return typeof value === "object" && value !== null;
    },
    toggleOption(key) {
      if (this.projectConfig.hasOwnProperty(key)) {
        this.projectConfig[key] = !this.projectConfig[key];
      }
    },
    closeSelectProjectConfig() {
      this.showProjectConfigurationAccessModal = false;
    },
    selectProjectConfig(i, index) {
      this.clientDetail = "";
      this.clientDetail = i.firstname + " " + i.lastname;
      this.projectIndex = null;
      this.projectIndex = index;
      this.isCollapsed = false;
      this.isAddingProject = true;
      this.projectConfig = i.accessConfiguration;
      this.showProjectConfigurationAccessModal = true;
    },
    checkEmail(searchValue) {
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/; // Regex pattern for email validation
      this.isValidEmail = emailRegex.test(searchValue.toLowerCase());
      this.showAddButton = true;
      this.correctEmail = searchValue;
      for (const email of this.users) {
        if (email.email.toLowerCase().includes(searchValue.toLowerCase())) {
          this.showAddButton = false;
          break;
        }
      }
    },
    closePopUp() {
      this.newAccess = "";
      this.$emit("closePopUp", true);
    },
    sendInvite() {
      // this.newAccess.forEach(item => {
      //  this.$set(item, 'accessConfiguration', this.projectsData);
      // });
      let newAccess = this.newAccess;
      this.$emit("send-invite", {
        accessList: newAccess,
        clientID: this.client.clientID
      });
      this.isCollapsed = false;
      this.newAccess = [];
    },
    fetchUsers() {
      this.fetchingUsers = true;
      let userWaccess = this.client.accessList;
      let exclude = userWaccess.map(user => {
        return user.adminID._id;
      });
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/admin/get-all`,
          {
            exclude
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.fetchingUsers = false;
          this.users = response.data.admins;
        })
        .catch(error => {
          console.log(error);
        });
    },
    removeAccessModal(adminID, adminName) {
      this.adminID = adminID;
      this.adminName = adminName;
      this.showRemoveAccessModal = true;
    },
    removeAccess() {
      this.$emit("remove-access", {
        adminID: this.adminID,
        clientID: this.client.clientID
      });
    }
  }
};
</script>

<style lang="scss">
.animate {
  animation-duration: 1s; /* Increase the duration to 1s for a smoother effect */
  animation-name: animate-fade-move;
  animation-fill-mode: forwards; /* Change to 'forwards' to keep the final state */
}

@keyframes animate-fade-move {
  0% {
    opacity: 0;
    transform: translateX(50px); /* Adjust the value based on your needs */
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@import "@core/scss/vue/libs/vue-select.scss";
.project-form-container {
  display: flex;
  align-items: center;
  .projectConfigContainer {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 20px !important;
    justify-content: center !important;
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }

  .card-config {
    padding: 10px;
    border: none;
    margin-bottom: 0.5rem;
    width: 460px !important;
    border-radius: 5px;

    box-shadow: $box-shadow;
    transition: all 0.3s ease-in-out, background 0s, color 0s, border-color 0s;
    h3 {
      font-size: 18px;
    }

    h4 {
      font-size: 16px;
    }

    ul {
      list-style-type: none;
      padding: 0;
    }

    .nested-item {
      margin-left: 0px;
      vertical-align: middle;
      // margin-top: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    input[type="checkbox"] {
      margin-right: 6px;
    }
  }

  .project-form-container {
    display: flex;
    align-items: center;
  }
  .card-container {
    display: flex;
    flex-wrap: wrap;
  }
}
.maximum-width {
  width: 340px;
}
.full-width {
  flex-grow: 1;
}
.button-text {
  display: inline-block;
  vertical-align: middle;
}
.popup-modal {
  display: flex;
  justify-content: center;
}
</style>
