<template>
  <div>
    <b-card style="background-color: #F5F5F5; padding:10px" v-if="filter" id="filters-card">
      <div
        style="text-align: center;justify-content:center; align-item:center; padding:10px"
      >
        <img
          src="@/assets/images/logo/d2scale-Bot.png"
          alt="access-control"
          style="max-width: 80px; margin-top: -2px;"
        />
      </div>
      <div style="padding: 10px;text-align: center;justify-content:center;">
        <h3 class="app-title md">
          Hello, {{ this.adminFirstName }}! Am Ready For Help You
        </h3>
        <p style="font-weight: 500;">
          Ask me anything what's are on your mind. Am here to assist you!
        </p>
      </div>
      <div class="chat-div mb-1">
        <div
          v-for="(item, index) in items"
          :key="index"
          :style="{ background: getRandomColor(),cursor:'pointer', padding:'6px 10px',color:'#7c0cfc'}"
          class="badge"
        >
          {{ item }}
        </div>
      </div>
      <div style="background-color: #ffffff; border-radius:5px; min-height:100px; max-height:auto; margin:10px 10px">
         <div style="width: 500px;">
          <div style="background-color: #ffffff; border:1px;width: 438px; padding:10px;box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 12px 0 rgba(0, 0, 0, 0.05); font-weight:600">
            <span> <b-icon
              icon="circle-fill"
              style="margin-right: 4px;"
              font-scale="0.8"
              color="#41B06E"
              class="dotLive"
            />D2Scale-GPT</span>
          
            
          </div>
          <div style="padding: 10px;">
            <!-- <div class="message-container-main">
              <img :src="profileImage" alt="Profile Picture" class="profile-pic">
              <div class="message-content">
                  <div class="name">{{ this.adminFirstName }}</div>
                  <div class="chat-bubble">Hello ! What's Last Week ROAS?</div>
                  <span class="time-stamp">09:25 AM</span>
              </div>
          </div> -->
          <div class="chat-wrapper">
            <div class="chat-message">
                <div class="message-content">
                  <div class="name" style="margin-left: 10px; text-align:right">{{ this.adminFirstName }}</div>
                  <div class="chat-bubble" style="margin-left: 10px;min-width:200px">What's Weekly ROAS.?</div>
                  <span class="time-stamp-user">09:25 AM</span>
                </div>
                <div v-if="profileImage.length > 9">
                  <img  :src="profileImage" alt="User" class="profile-image">
                </div>
                <div v-else>
                  <b-avatar style="margin-left: 10px;" variant="primary" :text="this.adminFirstName[0]"></b-avatar>
                </div>
            </div>
        </div>
            <div class="message-container">
              <b-avatar variant="primary" text="D"></b-avatar>
              <div class="message-content">
                  <div class="name" style="margin-left: 10px;">D2Scale-GPT</div>
                  <div class="chat-bubble" style="margin-left: 10px;">Last Week ROAS : 999</div>
                  <span class="time-stamp">09:25 AM</span>
              </div>
          </div>
          
          </div>
        
         </div>
      </div>
      <div style="display: flex; justify-content:center; gap:10px">
        <div
          style="background-color: #7c0cfc; width:35px; height:35px; padding:7px; border-radius:20px; cursor:pointer"
        >
          <b-icon class="upDownIcon" icon="mic-fill" style="color: white;" />
        </div>
        <div >
          <input placeholder="Ask What's on mind...." style="padding:8px 10px;border:none; border-radius:10px;font-weight:500; width:350px"/>
        </div>
        <div
          style="background-color: #7c0cfc; width:35px; height:35px; padding:7.5px; border-radius:20px; cursor:pointer"
        >
          <img src="@/assets/images/logo/send-fill.svg" alt="access-control" />
        </div>
      </div>
    </b-card>
    <!-- <b-card v-if="filter" id="filters-card">
        <div class="audio-recorder">
          <h1 class="title">Ask your Query</h1>
          <b-form-select
            v-model="selectedLanguage"
            :options="formattedLanguages"
            class="language-select mx-1"
            :placeholder="'Select Language'"
          />
      
          <div class="button-container">
            <button 
              @click="isRecording ? stopRecording() : startRecording()" 
              class="record-button"
            >
              <span v-if="isRecording">⏹ Stop Recording</span>
              <span v-else>  <b-icon
                class=""
                icon="chat-dots"
              /> Start speaking to transcribe....</span>
            </button>
          </div>
          
          <div v-if="isRecording" class="status">Recording...</div>
      
          <div v-if="transcription" class="transcription">
            <h2>Transcription:</h2>
            <p>{{ transcription }}</p>
          </div>
      
          <div v-if="error" class="error">{{ error }}</div>
        </div>
        <b-row>
        </b-row>
      </b-card> -->
    <div id="google-ads-filter-float">
      <b-button @click="filter = !filter" size="md" variant="primary" class="">
        <div v-if="!filter">
          <img
            src="@/assets/images/logo/Frame.png"
            alt="access-control"
            style="max-width: 18px; margin-top: -2px;"
          />
          <span style="font-size: 12px;"> D2Scale-GPT</span>
        </div>
        <feather-icon v-else size="14" icon="XCircleIcon" />
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BFormGroup,
  BRow,
  BCol,
  BCardText,
  BCardTitle,
  BIcon,
  BFormSelect,
  BBadge,
  BAvatar
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "axios";
import useJwt from "@/auth/jwt/useJwt";
import Cookies from "js-cookie";
import { getUserToken } from "@/auth/utils";

export default {
  components: {
    BButton,
    BCard,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BCardText,
    BCardTitle,
    BIcon,
    BBadge,
    BFormSelect,
    BAvatar
  },
  props: {
    clientID: {
      type: String,
    },
  },
  data() {
    return {
      profileImage: "",
      colors: ['#ffffff', '#ffffff', '#ffffff'],
      items: ['Last Week ROAS', 'Last Month ROAS'],
      adminFirstName: "",
      selectedLanguage: "hi-IN",
      languages: [
        { code: "hi-IN", name: "HINDI" },
        { code: "bn-IN", name: "BENGALI" },
        { code: "kn-IN", name: "KANNADA" },
        { code: "ml-IN", name: "MALAYALAM" },
        { code: "mr-IN", name: "MARATHI" },
        { code: "od-IN", name: "ODIYA" },
        { code: "pa-IN", name: "PUNJABI" },
        { code: "ta-IN", name: "TAMIL" },
        { code: "te-IN", name: "TELUGU" },
        { code: "gu-IN", name: "GUJARATI" },
      ],
      isRecording: false,
      mediaRecorder: null,
      audioChunks: [],
      transcription: "",
      error: "",
      filter: false,
      text: "",
      translatedText: "",
      language: "es", // Default translation to Spanish
      isListening: false,
    };
  },
  created() {
    this.profileImage = Cookies.get(useJwt.jwtConfig.adminUrl);
    this.adminFirstName = Cookies.get(useJwt.jwtConfig.adminFirstName);
  },
  computed: {
    formattedLanguages() {
      let language = this.languages.map((lang) => ({
        value: lang.code,
        text: lang.name,
      }));
      return language;
    },
    selectedLanguageName() {
      const selected = this.languages.find(
        (lang) => lang.code === this.selectedLanguage
      );
      return selected ? selected.name : "";
    },
  },
  methods: {
    getRandomColor() {
      const randomIndex = Math.floor(Math.random() * this.colors.length);
      return this.colors[randomIndex];
    },
    async startRecording() {
      this.resetState();
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = new MediaRecorder(stream);

      this.mediaRecorder.ondataavailable = (event) => {
        this.audioChunks.push(event.data);
      };

      this.mediaRecorder.onstop = this.handleRecordingStop;

      this.mediaRecorder.start();
      this.isRecording = true;
    },
    stopRecording() {
      this.mediaRecorder.stop();
      this.isRecording = false;
    },
    async handleRecordingStop() {
      const audioBlob = new Blob(this.audioChunks, { type: "audio/wav" });
      const form = new FormData();
      form.append("file", audioBlob);
      form.append("language_code", this.selectedLanguage);
      form.append("model", "saarika:v1");

      const options = {
        method: "POST",
        headers: {
          "api-subscription-key": "62b9a4e9-d62b-4987-a3ce-226c540c08e1", // Add your API key here
        },
        body: form,
      };

      try {
        const response = await fetch(
          "https://api.sarvam.ai/speech-to-text",
          options
        );
        const data = await response.json();
        this.transcription = data.transcript;
      } catch (err) {
        this.error = "Error fetching transcription.";
        console.error(err);
      }
    },
    resetState() {
      this.transcription = "";
      this.audioChunks = [];
      this.error = "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

#google-ads-filter-float {
  position: fixed;
  right: 30px;
  bottom: 40px;
  z-index: 9;

  .btn-primary {
    box-shadow: 0 0 10px #7367f0;
  }
}

#filters-card {
  box-shadow: 0px 0px 60px 20px rgb(34 41 47 / 15%);
  position: fixed;
  right: 30px;
  bottom: 65px;
  width: 100%;
  max-width: 520px;
  z-index: 9;
}
.audio-recorder {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  border: 2px solid #704c94;
  border-radius: 10px;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 24px;
  color: #704c94;
  margin-bottom: 20px;
}

.language-select {
  width: 100%;
  max-width: 400px; /* Limit the width for better appearance */
  margin-bottom: 15px;
}

.button-container {
  margin-bottom: 15px;
}

.record-button {
  background-color: #704c94;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.record-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.record-button:hover:not(:disabled) {
  background-color: #704c94;
}

.status {
  font-size: 18px;
  color: #ff0000; /* Red for recording status */
  margin-top: 10px;
}

.transcription {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #e8f5e9; /* Light green background */
  width: 100%;
  max-width: 600px;
}

.error {
  color: #ff0000; /* Red for error messages */
  margin-top: 10px;
}
.chat-div{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 5px;
  width: 450px;
  padding: 10px;
  .badge {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.6rem 1.2rem;
    border-radius: 12px;
    font-size: 1rem;
    color: #333;
  }
}

.message-container {
  display: flex;
  align-items: flex-start;
  margin: 10px;
}

.message-container-main{
  display: flex;
  align-items: flex-start;
  margin: 10px;
}
.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.message-content {
  display: flex;
  flex-direction: column;
}

.name {
  font-weight: bold;
  margin-bottom: 5px;
}

.chat-bubble {
  background-color: #f1f5f9;
  color: #333;
  padding: 10px 15px;
  border-radius: 10px;
  max-width: 100%;
  word-wrap: break-word;
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 5px;
}
.time-stamp-user {
  font-size: 12px;
  color: gray;
  align-self: flex-start;
  margin-left: 8px;
}
.time-stamp {
  font-size: 12px;
  color: gray;
  align-self: flex-end;
}
.blink_text {
  color: black;
  animation-name: blinker;
  animation-duration: 2.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.chat-wrapper {
  width: 350px;
  display: flex;
  justify-content: flex-end;
}

.chat-message {
  display: flex;
  align-items: flex-start;
  max-width: 60%;
  margin-bottom: 20px;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 10px;
  object-fit: cover;
}

.message-content-user {
  color: white;
  border-radius: 18px;
  padding: 10px 15px;
  position: relative;
  display: inline-block;
  max-width: 220px;
  word-wrap: break-word;
}

.message-content p {
  margin: 0;
  font-size: 14px;
}

.message-time {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  display: block;
  text-align: right;
  margin-top: 5px;
}
</style>
